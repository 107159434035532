@font-face {
  font-family: "DDCHardware";
  font-weight: 900;
  src: local("DDCHardware"),
    url("./assets/fonts/DDCHardware-Regular.otf") format("opentype");
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #cee2ff;
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
}

button {
  padding: 1px 1px;
  margin: 0px;
}
.btn-primary {
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 3px;
  color: #ecf0f1;
  background-color: #4f4eff;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  cursor: pointer;
}
.btn-secondary {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 1px;
  color: #4400ff;
  background-color: #ecf0f1;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #1abc9c;
}
.btn-secondary:hover {
  background-color: #1abc9c;
  color: white;
}

section {
  height: 100vh;
  width: 100%;
}
section:nth-child(1) {
  background: none;
}
section:nth-child(2) {
  background: none;
}
section:nth-child(3) {
  background: none;
}
section:nth-child(4) {
  background-color: none;
}
section:nth-child(5) {
  background: white;
}
section:nth-child(6) {
  height: 100%;
  background: -o-linear-gradient(
    top,
    #1b283a 0%,
    #1b283a 70%,
    #f6f6f6 70%,
    #f6f6f6 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1b283a),
    color-stop(70%, #1b283a),
    color-stop(70%, #f6f6f6),
    to(#f6f6f6)
  );
  background: linear-gradient(
    to bottom,
    #1b283a 0%,
    #1b283a 70%,
    #f6f6f6 70%,
    #f6f6f6 100%
  );
}
section:nth-child(7) {
  height: 100%;
  background: -o-linear-gradient(
    bottom,
    #1b283a 0%,
    #1b283a 70%,
    #f6f6f6 70%,
    #f6f6f6 100%
  );
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#1b283a),
    color-stop(70%, #1b283a),
    color-stop(70%, #f6f6f6),
    to(#f6f6f6)
  );
  background: linear-gradient(
    to top,
    #1b283a 0%,
    #1b283a 70%,
    #f6f6f6 70%,
    #f6f6f6 100%
  );
}
section:nth-child(8) {
  height: 100%;
  background: #1b283a;
}
section:nth-child(9) {
  background-color: #1b283a;
}

img {
  max-width: 100%;
  max-height: 100%;
}
