div.cover {
  font-family: "Lato";
  font-weight: 300;
  line-height: 150%;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 999;
  overflow: visible !important;

  div.logo {
    position: absolute;
    margin: auto;
    color: #231f20;
    width: 50%;
    height: 40%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    text-align: center;
    a {
      cursor: pointer;
      margin: 5%;
      color: black;
      -webkit-transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
      -moz-transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
      -o-transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
      transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
    }
    .btn-group {
      display: flex;
      justify-content: center;
      margin: 30px;
    }
    img.logo,
    p.text {
      padding: 30px 20px;
    }
    img.logo {
      width: auto;
      height: 100%;
      transition-duration: 500ms;
      cursor: pointer;
      &:hover {
        -ms-transform: scale(1.1, 1.1);
        /* IE 9 */
        -webkit-transform: scale(1.1, 1.1);
        /* Safari */
        transform: scale(1.1, 1.1);
      }
    }
  }
}

@keyframes sway {
  0% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
.object {
  position: absolute;
  animation: sway 2.4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  left: 0;
  right: 0;
  height: 5%;
  z-index: 999;
  text-transform: uppercase;
}

.object-shape {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #222222;
  margin: 0 auto;
  position: relative;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  font-weight: 800;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.object-shape span {
  font-size: 22px;
  color: white;
}

.object-rope {
  height: 100%;
  width: 5px;
  background-color: #222222;
  content: "";
  display: block;
  margin-left: 50%;
}

@media (orientation: portrait) {
  img.logo {
    width: 100% !important;
    height: auto !important;
  }
}
@media all and (orientation: portrait) {
  img.logo {
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 1400px) {
  body {
    background-size: 200%;
    font-size: 100%;
  }
}
@media only screen and (max-width: 650px) {
  body {
    font-size: 80%;
    background-size: 230%;
    background-position: center;
    background-repeat: no-repeat;
  }
  #cover div#logo .text {
    padding: 30px 0px;
  }
}
