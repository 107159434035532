.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.flex-container > section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.flex-container > nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.sc-card-section {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sc-card-section--header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 65px;
  padding-top: 7%;
  padding-bottom: 2rem;
}

.sc-card-section--header__title {
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.sc-card-section--heeader__button {
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  text-decoration: none;
  letter-spacing: 3px;
  color: #ecf0f1;
  background-color: #4f4eff;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.sc-card-section .card-container {
  padding-top: 0;
}

.sc-card-section .card-container::-webkit-scrollbar {
  display: none;
}

.sc-card-section .gallery-toggle {
  display: none;
}

.sc-card-section .price-tag {
  display: none;
}

.sc-card-section .buy-button {
  background-color: #4f4eff;
  border-radius: 0;
}

.sc-card-section .product-card {
  width: 350px;
  height: 550px;
}

.sc-card-section .card {
  border-radius: 0px;
}

.sc-card-section .product-card-gallery .gallery-item {
  border-radius: 0px;
}

.sc-maps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 1.25rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sc-card-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sc-our-work--header {
    width: 81%;
    margin-left: 12%;
    margin-top: 5%;
    margin-bottom: 2%;
  }

  .sc-our-work .card-container {
    margin-left: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sc-our-work--header {
    width: 79%;
    margin-left: 12%;
    margin-top: 3%;
    margin-bottom: 1%;
  }

  .sc-our-work .card-container {
    margin-left: 0px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sc-our-work--header {
    width: 58%;
    margin-left: 21%;
    margin-top: 3%;
    margin-bottom: 1%;
  }

  .sc-our-work .card-container {
    margin-left: 10%;
    width: 80%;
  }
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 317px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .sc-card-section .product-card {
    width: 100px;
    height: 420px;
  }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sc-card-section--header {
    padding-left: 9%;
    width: 90%;
  }
  .sc-map-section .sc-contact {
    display: block;
    height: 100%;
    padding-left: 5%;
  }
}
/* Portrait */
@media only screen and (min-device-width: 321px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sc-card-section--header {
    padding-left: 9%;
    width: 90%;
  }
  .sc-map-section .sc-contact {
    display: block;
    height: 100%;
    padding-left: 5%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .sc-card-section--header {
    padding-left: 9%;
    width: 90%;
  }
  .sc-map-section .sc-contact {
    display: block;
    height: 100%;
    margin-left: 5%;
  }
}

/* Portrait */
@media only screen and (min-width: 900px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .sc-card-section .product-card {
    width: 20%;
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .sc-card-section .card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.sc-cases-container {
  padding: 1rem 1rem;
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;

  header {
    padding-bottom: 5rem;
    padding-top: 3rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding-bottom: 1rem;
    background: #fff;
    color: #202020;
  }

  li:hover {
    -webkit-box-shadow: 1px 2px 3px 0px rgba(25, 25, 25, 0.6);
    box-shadow: 1px 2px 3px 0px rgba(25, 25, 25, 0.6);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  h1 {
    background-color: darken(steelblue, 10%);
    color: #fff;
    padding: 1.25rem;
  }
  h2 {
    margin: 0;
  }
  p {
    color: #555;
    line-height: 1.5;
    font-size: 0.75rem;
    letter-spacing: 2px;
  }
  img {
    width: 100%;
    margin-bottom: 1.5rem;
    height: 315px;
  }

  /* Flexbox Styling */

  .item-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .item {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin: 0 0 1.25rem;
  }
  .item:hover {
    perspective: 1000;
    -webkit-perspective: 1000;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-box-shadow: 0 10px 10px -10px rgba(#0000, 1);
    box-shadow: 0 10px 10px -10px rgba(#0000, 1);
  }
  .item-content {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
  }
  .item-text {
    padding-top: 1rem;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4rem;
  }

  @media (min-width: 36em) {
    .item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(50% - 1.25rem / 2);
      flex: 0 0 calc(50% - 1.25rem / 2);
      margin-left: 1.25rem;
    }

    .item:nth-child(odd) {
      margin-left: 0;
    }
  }

  @media (min-width: 60em) {
    .item {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% / 3 - 0.875rem);
      flex: 0 0 calc(100% / 3 - 0.875rem);
    }

    .item:nth-child(odd),
    item:nth-child(even) {
      margin-left: 1.25rem;
    }

    .item:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  .item-content {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  .item-text {
    padding-top: 1rem;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4rem;
  }
}

//IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sc-cases-container {
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 1%;
    margin-top: 5%;
  }
}

/* Microsoft Edge*/
@supports (-ms-ime-align: auto) {
  .sc-cases-container {
    width: 1200px;
  }
  .sc-maps {
    padding-bottom: 80px;
  }

  .sc-about {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* Firefox */
@-moz-document url-prefix() {
  .sc-cases-container {
    width: 1200px;
  }
  .footer-wrapper {
    width: 1200px;
  }
}
/* ----------- Laptops ----------- */
/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1640px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (-o-min-device-pixel-ratio: 2/1),
  screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
}
