@import url("https://fonts.googleapis.com/css?family=Orbitron");

.box {
  width: 450px;
  height: 180px;
  background: none;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
.underline {
  width: 100%;
  background: #48a1a1;
  height: 10px;
  margin-top: 130px;
  animation: underline 6s ease-in-out infinite;
}
@keyframes underline {
  0% {
    width: 0%;
    float: left;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
    float: right;
  }
}
.L {
  position: absolute;
  margin: 80px 0 0 70px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: L 6s ease-in-out infinite;
}
@keyframes L {
  10% {
    margin-top: 80px;
    background: white;
  }
  17.5% {
    margin-top: 70px;
    background: #48a1a1;
  }
  25% {
    margin-top: 80px;
  }
}
.O {
  position: absolute;
  margin: 80px 0 0 115px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: O 6s ease-in-out infinite;
}
@keyframes O {
  14.15% {
    margin-top: 80px;
    background: white;
  }
  22% {
    margin-top: 70px;
    background: #48a1a1;
  }
  29.5% {
    margin-top: 80px;
  }
}
.A {
  position: absolute;
  margin: 80px 0 0 160px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: A 6s ease-in-out infinite;
}
@keyframes A {
  18.3% {
    margin-top: 80px;
    background: white;
  }
  26% {
    margin-top: 70px;
    background: #48a1a1;
  }
  33.5% {
    margin-top: 80px;
  }
}
.D {
  position: absolute;
  margin: 80px 0 0 205px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: D 6s ease-in-out infinite;
}
@keyframes D {
  22.45% {
    margin-top: 80px;
    background: white;
  }
  30% {
    margin-top: 70px;
    background: #48a1a1;
  }
  37.5% {
    margin-top: 80px;
  }
}
.I {
  position: absolute;
  margin: 80px 0 0 250px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: I 6s ease-in-out infinite;
}
@keyframes I {
  26.6% {
    margin-top: 80px;
    background: white;
  }
  33.7% {
    margin-top: 70px;
    background: #48a1a1;
  }
  40.2% {
    margin-top: 80px;
  }
}
.N {
  position: absolute;
  margin: 80px 0 0 295px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: N 6s ease-in-out infinite;
}
@keyframes N {
  31.75% {
    margin-top: 80px;
    background: white;
  }
  39% {
    margin-top: 70px;
    background: #48a1a1;
  }
  46.5% {
    margin-top: 80px;
  }
}
.G {
  position: absolute;
  margin: 80px 0 0 340px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  animation: G 6s ease-in-out infinite;
}
@keyframes G {
  35.9% {
    margin-top: 80px;
    background: white;
  }
  43.4% {
    margin-top: 70px;
    background: #48a1a1;
  }
  50.9% {
    margin-top: 80px;
  }
}
